import { API } from 'aws-amplify'
import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import './Employees.scss'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import { getUUID } from '../../../utility'
import Item from './Item/Item'

const Employees = () => {
  const translation = useSelector((state) => state.translation)
  const [list, setList] = useState(null)

  useEffect(() => {
    const pullProfile = async () => {
      const { status, list } = await API.get('intranet', `/employee/list`)
      if (status && list) {
        setList(list)
      }
    }
    if (!list) {
      pullProfile()
    }
  }, [list])

  return (
    <DashboardLayout card={true}>
      {list && (
        <div id="employees">
          <CardHeader headline={translation['dashboard.employees.headline']} />
          <CardContent>
            {list.map((e) => (
              <Item key={getUUID()} data={e} />
            ))}
          </CardContent>
        </div>
      )}
    </DashboardLayout>
  )
}

export default Employees
