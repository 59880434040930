import { useState } from 'react'
import { API } from 'aws-amplify'
import Button from '../../../UI/Button/Button'
import { getPrettyDate } from '../../../../utility'
import { useSelector } from 'react-redux'
import './Request.scss'

const Request = ({ data }) => {
  const [isVisible, setIsVisible] = useState(true)
  const translation = useSelector((state) => state.translation)

  const submit = async (newStatus) => {
    const { status } = await API.post('intranet', '/holiday/response', { body: { id: data.id, status: newStatus } })
    if (status) {
      setIsVisible(false)
    }
  }

  const accept = () => submit('accepted')
  const decline = () => submit('declined')

  if (!isVisible) return <></>
  return (
    <div className="holiday-request">
      <div className="prop">{`${data.firstname} ${data.lastname}`}</div>
      <div className="prop">
        {translation['dashboard.holiday.request.start.label']}
        {getPrettyDate(data.start)}
      </div>
      <div className="prop">
        {translation['dashboard.holiday.request.end.label']}
        {getPrettyDate(data.end)}
      </div>
      <div className="prop">
        {translation['dashboard.holiday.request.days.label']}
        {data.requiredDays}
      </div>
      <div className="cta-wrapper">
        <div className="cta">
          <Button onClick={accept} text={translation['dashboard.holiday.request.accept']} />
        </div>
        <div className="cta">
          <Button onClick={decline} text={translation['dashboard.holiday.request.decline']} />
        </div>
      </div>
    </div>
  )
}

export default Request
