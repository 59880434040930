export const SET_TRANSLATION = 'set_translation'

export const SET_USER_TOKEN = 'set_user_token'
export const SET_USER = 'set_user'

export const SET_SETTINGS = 'set_settings'

export const SET_TRANSLATIONS = 'set_translations'
export const SET_LANG_OPTIONS = 'set_lang_options'
export const SET_LANG = 'set_lang'

export const SET_NEW_OBJECT = 'set_new_object'
export const SET_NEW_OBJECT_NAVIGATION_INDEX = 'set_new_object_navigation_index'
export const TRIGGER_NEW_OBJECT_CLEANUP = 'trigger_new_object_cleanup'
