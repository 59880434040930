import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Login from './components/Auth/Login/Login'
import PasswordLost from './components/Auth/PasswordLost/PasswordLost'
import DashboardRouting from './DashboardRouting'
import AdminRouting from './AdminRouting'
import NotFound from './components/NotFound/NotFound'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

function App() {
  return (
    <Router>
      <Switch>
        {/* LANDINGPAGE */}
        <Route exact={true} path="/">
          <Redirect to={'/login'} />
          {/* <Redirect to={!!user ? '/dashboard/my-objects' : '/login'} /> */}
        </Route>
        {/* ADMIN */}
        <Route exact={false} path="/admin" component={AdminRouting} />
        {/* LOGIN */}
        <Route exact={true} path="/login" component={Login} />
        {/* PASSWORD LOST */}
        <Route exact={true} path="/password-lost" component={PasswordLost} />
        {/* DASHBOARD - ROUTING */}
        <Route exact={false} path="/dashboard" component={DashboardRouting} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
