import './Header.scss'
import { useHistory } from 'react-router-dom'
import headerLogo from '../../../assets/image/logo.png'
import { useEffect } from 'react'
import { projectName } from '../../../utility'

const Header = () => {
  const history = useHistory()

  const handleScroll = () => {
    document.querySelector('#header').className = window.scrollY > 80 ? 'user-scrolled' : ''
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const goHome = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    history.push('/')
  }

  return (
    <div id="header">
      <div className="logo-wrapper">
        <div className="logo" onClick={goHome}>
          <img src={headerLogo} alt={projectName} />
        </div>
      </div>
    </div>
  )
}

export default Header
