import { useHistory } from 'react-router'
import './Item.scss'

const Item = ({ data }) => {
  const history = useHistory()

  const open = () => {
    history.push(`/dashboard/employees/${data.id}`)
  }

  return (
    <div className="employee-list-item" onClick={open}>
      <div className="prop">{`${data.firstname} ${data.lastname}`}</div>
      <div className="prop">{data.position}</div>
    </div>
  )
}

export default Item
