import * as types from '../type/types'
import * as user from './user'
import * as translations from './translations'
import * as settings from './settings'
import defaultState from './defaultState'

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_USER_TOKEN:
      return user.setToken(state, action)
    case types.SET_USER:
      return user.set(state, action)
    case types.SET_SETTINGS:
      return settings.set(state, action)
    case types.SET_LANG_OPTIONS:
      return translations.setLangOptions(state, action)
    case types.SET_LANG:
      return translations.setLang(state, action)
    default:
      return state
  }
}

export default reducer
