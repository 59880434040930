import { API } from 'aws-amplify'
import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import Input from '../../UI/Input/Input'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import './Profile.scss'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import Button from '../../UI/Button/Button'
import { useParams } from 'react-router'
import ImageWrapper from '../../ImageWrapper/ImageWrapper'
import { fallbackProfilImage } from '../../../utility'

const Profile = () => {
  const translation = useSelector((state) => state.translation)
  const user = useSelector((state) => state.user)
  const params = useParams()
  const [message, setMessage] = useState(null)
  const [phone, setPhone] = useState('')
  const [bio, setBio] = useState('')
  const [holidayDayAmount, setHolidayDayAmount] = useState('')
  const [profile, setProfile] = useState(null)

  const isOwnProfile = () => user.id === profile.id
  const isAdmin = () => user && user.admin === true

  useEffect(() => {
    const pullProfile = async (id) => {
      const { status, profile } = await API.get('intranet', `/employee/get/${id}`)
      if (status && profile) {
        setProfile(profile)
        setHolidayDayAmount(profile.holidayDayAmount || '')
        setPhone(profile.phone || '')
        setBio(profile.bio || '')
      }
    }
    if (user) {
      const id = params.id ? params.id : user.id
      if (!profile || profile.id !== id) {
        pullProfile(id)
      }
    }
  }, [profile, params.id, user])

  const submit = async () => {
    try {
      if (isOwnProfile() || isAdmin()) {
        const body = { id: profile.id, phone, bio }
        if (isAdmin()) {
          body.holidayDayAmount = holidayDayAmount
        }
        const { status } = await API.post('intranet', '/employee/update', { body })
        setMessage(status ? translation['dashboard.profile.update-success'] : translation['dashboard.profile.update-failure'])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const updateProfileImage = async (imageKey) => await API.post('intranet', '/employee/update', { body: { id: user.id, imageKey } })

  return (
    <DashboardLayout card={true}>
      {profile && (
        <div id="profile">
          <CardHeader headline={translation['dashboard.profile.headline']} />
          <CardContent>
            <div className="image-wrapper">
              <ImageWrapper imageKey={profile.imageKey} fallbackImage={fallbackProfilImage} round={true} uploadedCallback={isOwnProfile() ? updateProfileImage : null} />
            </div>
            <Input className="input" disabled={true} value={profile.firstname} placeholder={translation['dashboard.profile.firstname']} />
            <Input className="input" disabled={true} value={profile.lastname} placeholder={translation['dashboard.profile.lastname']} />
            <Input className="input" disabled={true} value={profile.mail} placeholder={translation['dashboard.profile.mail']} />
            <Input className="input" disabled={true} value={profile.position} placeholder={translation['dashboard.profile.position']} />
            <Input type="date" className="input" disabled={true} value={profile.birthday} placeholder={translation['dashboard.profile.birthday']} />
            <Input className="input" disabled={!isOwnProfile()} value={phone} onChange={setPhone} placeholder={translation['dashboard.profile.phone']} />
            <Input className="input" disabled={!isOwnProfile()} value={bio} onChange={setBio} placeholder={translation['dashboard.profile.bio']} />
            {(isOwnProfile() || isAdmin()) && (
              <Input className="input" disabled={!isAdmin()} value={holidayDayAmount} onChange={setHolidayDayAmount} placeholder={translation['dashboard.profile.holiday-day-amount']} />
            )}
            {message && <div className="message">{message}</div>}
            {(isOwnProfile() || isAdmin()) && <Button fullWidth={false} onClick={submit} text={translation['settings.save-cta']} />}
          </CardContent>
        </div>
      )}
    </DashboardLayout>
  )
}

export default Profile
