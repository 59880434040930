import de, { langKey } from './translationFiles/de'

const defaultState = {
  translation: de,
  user: null,
  currentLang: langKey,
  settings: null,
}

export default defaultState
