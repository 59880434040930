import './Login.scss'
import { setUser } from '../../../action/user'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Link from '../../UI/Link/Link'
import { Auth } from 'aws-amplify'

const Login = () => {
  const history = useHistory()
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const translation = useSelector((state) => state.translation)
  const [passwordResetEnabled, setPasswordResetEnabled] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    const signOut = async () => await Auth.signOut()
    signOut()
  }, [])

  const login = async () => {
    try {
      setError(null)
      if (mail.length > 0 && password.length > 0) {
        const response = await Auth.signIn(mail, password)
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setPasswordResetEnabled(true)
        }
        const user = await Auth.currentUserInfo()
        if (user) {
          dispatch(setUser(user))
          history.push('/dashboard')
        }
      } else {
        setError(translation['login.wrong-credentials'])
      }
    } catch (err) {
      setError(translation['login.wrong-credentials'])
    }
  }

  const resetPasswort = async (e) => {
    e.preventDefault()
    try {
      const userData = await Auth.signIn(mail, password)
      await Auth.completeNewPassword(userData, newPassword)
      const user = await Auth.currentUserInfo()
      dispatch(setUser(user))
      history.push('/dashboard')
    } catch (err) {
      setError(translation['login.wrong-credentials'])
    }
  }

  const submit = (e) => {
    e.preventDefault()
    login()
  }

  return (
    <Layout>
      <div id="login">
        <div className="form-wrapper">
          <div className="headline">{translation['login.headline']}</div>
          <form onSubmit={passwordResetEnabled ? resetPasswort : submit}>
            <div className="element-wrapper">
              <Input type="email" disabled={passwordResetEnabled} placeholder={translation['login.mail']} value={mail} onChange={setMail} />
            </div>
            {!passwordResetEnabled && (
              <div className="element-wrapper">
                <Input type="password" placeholder={translation['login.password']} value={password} onChange={setPassword} />
              </div>
            )}
            {passwordResetEnabled && (
              <div className="element-wrapper">
                <Input type="password" placeholder={translation['login.new.password']} value={newPassword} onChange={setNewPassword} />
              </div>
            )}
            {error ? <div className="error">{error}</div> : ''}
            <div className="element-wrapper">
              <Button text={translation[!passwordResetEnabled ? 'login.button' : 'login.button.save']} type={'submit'} />
            </div>
            <div className="switches">
              <div className="switch">
                <Link href="/password-lost" text={translation['login.password-lost']} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Login
