const translations = {
  'dashboard.profile.headline': 'Profil',
  'dashboard.profile.firstname': 'Vorname',
  'dashboard.profile.lastname': 'Vorname',
  'dashboard.profile.mail': 'E-Mail Adresse',
  'dashboard.profile.birthday': 'Geburtstag',
  'dashboard.profile.phone': 'Telefon',
  'dashboard.profile.position': 'Position',
  'dashboard.profile.holiday-day-amount': 'Urlaubstage',
  'dashboard.profile.update-success': 'Daten erfolgreich aktualisiert.',
  'dashboard.profile.update-failure': 'Fehler beim Aktualisieren Ihrer Daten.',
  'dashboard.profile.bio': 'Bio',

  'dashboard.employees.headline': 'Mitarbeiter',

  'dashboard.holiday.headline': 'Urlaub',
  'dashboard.holiday.start': 'Start',
  'dashboard.holiday.end': 'Ende',
  'dashboard.holiday.days': 'Benötigte Tage',
  'dashboard.holiday.request.send.success': 'Urlaubsantrage erfolgreich eingereicht. Ihr Vorgesetzter wird diesen nun prüfen.',
  'dashboard.holiday.request.send.failure': 'Fehler beim Einreichen Ihres Urlaubantrags.',
  'dashboard.holiday.request.accept': 'Annehmen',
  'dashboard.holiday.request.decline': 'Ablehnen',
  'dashboard.holiday.request.start.label': 'Start: ',
  'dashboard.holiday.request.end.label': 'Ende: ',
  'dashboard.holiday.request.days.label': 'Benötigte Tage: ',
  'dashboard.holiday.submit': 'Abschicken',

  'layout-dashboard.logout': 'Logout',

  'admin.navigation.statistics': 'Statistiken',
  'admin.navigation.settings': 'Einstellungen',
  'admin.settings.application': 'Anwendung',
  'admin.settings.application.frontend': 'Frontend',
  'admin.settings.application.backend': 'Backend',
  'admin.settings.group': 'Gruppe',
  'admin.settings.key': 'Key',
  'admin.settings.value': 'Wert',
  'admin.settings.translationKey': 'Übersetzungsschlüssel',
  'admin.settings.create': 'Hinzufügen',
  'admin.settings.export': 'Export',
  'admin.settings.export.close': 'Schließen',
  'admin.settings.import.close': 'Schließen',
  'admin.settings.import.process': 'Verarbeiten',
  'admin.settings.import': 'Import',
  'admin.settings.import.translations-as-settings': 'Import Translations as Settings',
  'admin.settings.import.translations-as-settings.group': 'Group',
  'admin.settings.import.translations-as-settings.close': 'Schließen',
  'admin.settings.import.translations-as-settings.process': 'Verarbeiten',
  'admin.settings.list-headline': 'Bestehende Einstellungen:',
  'admin.settings.create-message.success': 'Einstellung wurde gespeichert!',
  'admin.settings.create-error.duplicate': 'Dieser "Key" existiert bereits.',
  'admin.settings.create-error.value-missing': 'Alle Eingaben abgesehen von "Übersetzungsschlüssel" sind Pflicht.',
  'admin.settings.update-message.success': 'Gespeichert!',
  'admin.settings.update-message.failure': 'Fehler!',
  'admin.settings.update': 'Aktualisieren',

  'admin.translations.create-message.success': 'Übersetzung erfolgreich gespeichert!',
  'admin.translations.create-error.duplicate': 'Dieser "Key" existiert bereits.',
  'admin.translations.create-error.value-missing': 'Alle Eingaben sind Pflicht.',
  'admin.translations.lang-options': 'Unterstützte Sprachen:',
  'admin.translations.key': 'Key',
  'admin.translations.value': 'Wert',
  'admin.translations.create': 'Hinzufügen',
  'admin.translations.export': 'Export',
  'admin.translations.export.close': 'Schließen',
  'admin.translations.import.close': 'Schließen',
  'admin.translations.import.process': 'Verarbeiten',
  'admin.translations.import': 'Import',
  'admin.translations.import.translations-from-file.process': 'Verarbeiten',
  'admin.translations.import.translations-from-file.close': 'Schließen',
  'admin.translations.list-headline': 'Bestehende Übersetzungen:',
  'admin.translations.import.translations-from-file': 'Import Translations from File',
  'admin.translations.lang-selector.choose': 'Sprache wählen',
  'admin.translations.update': 'Aktualisieren',
  'admin.translations.update-message.success': 'Gespeichert!',
  'admin.translations.update-message.failure': 'Fehler!',

  'login.headline': 'Anmelden',
  'login.mail': 'E-Mail',
  'login.password': 'Passwort',
  'login.new.password': 'Neues Passwort',
  'login.button': 'Anmelden',
  'login.button.save': 'Speichern',
  'login.wrong-credentials': 'Falsche E-Mail oder Passwort.',
  'login.missing-inputs': 'E-Mail und Password sind Pflichtfelder.',
  'login.password-lost': 'Passwort vergessen?',
  'login.registration': 'Hier Registrieren',

  'registration.headline': 'Registrieren',
  'registration.salutation': 'Anrede',
  'registration.title': 'Titel',
  'registration.firstname': 'Vorname',
  'registration.lastname': 'Nachname',
  'registration.mail': 'E-Mail',
  'registration.password': 'Passwort',
  'registration.button': 'Registrieren',
  'registration.login': 'bereits registriert?',
  'registration.done.text': 'Wir haben Ihnen einen Bestätigungslink per E-Mail geschickt.',
  'registration.done.login': 'Zum Login',
  'registration.missing-inputs': 'Bitte überprüfen Sie Ihre Eingaben.',
  'registration.mail-duplicate': 'Ein Account mit dieser E-Mail Adresse existiert bereits.',
  'registration.unknown-error': 'Ein unbekannter Fehler ist aufgetreten.',

  'registration.confirm.success': 'Ihr Account wurde bestätigt, Sie können sich jetzt einloggen.',
  'registration.confirm.unknown-error': 'Ein unbekannter Fehler ist aufgetreten.',

  'password-lost.headline': 'Passwort vergessen?',
  'password-lost.login': 'Zum Login',
  'password-lost.missing-input': 'Bitte überprüfen Sie Ihre Eingaben.',
  'password-lost.unknown-error': 'Ein unbekannter Fehler ist aufgetreten.',
  'password-lost.mail': 'E-Mail',
  'password-lost.button': 'E-mail anfordern',
  'password-lost.explain': 'Geben Sie Ihre E-Mail Adresse ein um einen Änderungslink zu erhalten.',

  'password-reset.headline': 'Passwort zurücksetzen',
  'password-reset.login': 'Zum Login',
  'password-reset.missing-input': 'Bitte überprüfen Sie Ihre Eingaben.',
  'password-reset.unknown-error': 'Ein unbekannter Fehler ist aufgetreten.',
  'password-reset.code': 'Bestätigungscode',
  'password-reset.password': 'Passwort',
  'password-reset.button': 'Passwort zurücksetzen',
  'password-reset.explain': 'Geben Sie Ihr neues Passwort ein.',
  'password-reset.success': 'Sie haben Ihr Passwort erfolgreich zurückgesetzt, Sie könnnen sich jetzt mit Ihrem neuen Passwort einloggen.',
  'password-reset.mail-sent': 'Bitte prüfen Sie Ihr Postfach für den Bestätigungscode.',

  'layout-dashboard.navigation.admin.title': 'Admin',
  'layout-dashboard.navigation.admin.settings': 'Dashboard',
  'layout-dashboard.navigation.general.title': 'Allgemein',
  'layout-dashboard.navigation.profile': 'Profil',
  'layout-dashboard.navigation.vacation': 'Urlaub',
  'layout-dashboard.navigation.employees': 'Mitarbeiter',

  'layout-admin.logo-label': 'Admin Panel',
  'layout-admin.navigation.user.title': 'Benutzer',
  'layout-admin.navigation.user.list': 'Alle Nutzer',
  'layout-admin.navigation.user.add': 'Hinzufügen',
  'layout-admin.navigation.company.title': 'Unternehmen',
  'layout-admin.navigation.buying-profile.title': 'Ankausprofile',
  'layout-admin.navigation.object.title': 'Objekte',
  'layout-admin.navigation.settings.title': 'Einstellungen',
  'layout-admin.navigation.settings.global': 'Global',
  'layout-admin.navigation.settings.translation': 'Übersetzungen',
  'layout-admin.navigation.settings.geo-data': 'Geo-Daten',

  'settings.header.headline.prefix': 'Willkommen ',
  'settings.header.hint': 'Bitte vervollständigen Sie zunächst Ihr Profil',
  'settings.personal-data.headline': 'Persönliche Daten',
  'settings.personal-data.responsibilities.title': 'Ich bin zuständig für...',
  'settings.personal-data.salutation': 'Anrede',
  'settings.personal-data.title': 'Titel',
  'settings.personal-data.firstname': 'Vorname',
  'settings.personal-data.lastname': 'Nachname',
  'settings.personal-data.mail': 'E-Mail',
  'settings.personal-data.position': 'Position',
  'settings.personal-data.birthday': 'Geburtstag',
  'settings.personal-data.mobile-phone': 'Mobil',
  'settings.personal-data.phone': 'Telefon',
  'settings.personal-data.responsibility.other': 'Sonstiges',
  'settings.company-data.headline': 'Unternehmensdaten',
  'settings.company-data.name': 'Firmenname',
  'settings.company-data.corporate-form': 'Gesellschaftsform',
  'settings.company-data.street': 'Straße',
  'settings.company-data.street-number': 'Nr.',
  'settings.company-data.street-addition': 'Zusatz',
  'settings.company-data.phone': 'Allgemein Telefon',
  'settings.company-data.mail': 'Allgemein E-Mail',
  'settings.company-data.purchase-mail': 'Ankauf E-Mail',
  'settings.company-data.website': 'Website',
  'settings.company-data.court': 'Amtsgericht',
  'settings.company-data.registration-number': 'HRB',
  'settings.company-data.postcode': 'Postleitzahl',
  'settings.company-data.city': 'Stadt',
  'settings.company-data.intention.title': 'Wir sind...',
  'settings.save-cta': 'Speichern',
  'settings.save.success': 'Ihre Daten wurden erfolgreich gespeichert.',
  'settings.save.error': 'Ihre Daten konnten nicht gespeichert werden.',

  'new-objects.headline': 'Neue Angebote',
  'new-objects.tab.object-suggestions': 'Objektvorschläge',
  'new-object.suggestions.error': 'Ihre Vorschläge konnten nicht geladen werden.',
  'new-object.suggestions.no-results': 'Aktuell haben wir keine Vorschläge für Sie.',
  'new-objects.tab.requested': 'Angefragt',

  'my-objects.headline': 'Meine Objekte',
  'my-objects.tab.aktive-objects': 'Aktive Objekte',
  'my-objects.active.error': 'Ihre Objekte konnten nicht geladen werden.',
  'my-objects.tab.paused-objects': 'Pausierte Objekte',
  'my-objects.paused.error': 'Ihre Objekte konnten nicht geladen werden.',

  'my-objects.pipeline.navigation.asset-class.label': 'Assetklasse bestimmen',
  'my-objects.pipeline.navigation.location-criteria.label': 'Standortkriterien',
  'my-objects.pipeline.navigation.profit-object-criteria.label': 'Ertrags- und Objektkriterien',
  'my-objects.pipeline.navigation.extended-criteria.label': 'Erweiterte Kriterien',

  'my-objects.pipeline.assets-class.headline': 'Ankaufsprofil bezeichnen',
  'my-objects.pipeline.assets-class': 'Allgemein',
  'my-objects.pipeline.assets-class.asset-class': 'Assetklasse auswählen',
  'my-objects.pipeline.assets-class.project-type': 'Projektart',
  'my-objects.pipeline.assets-class.object-type': 'Objekttyp',
  'my-objects.pipeline.assets-class.investment-volume': 'Investitionsvolumen',
  'my-objects.pipeline.assets-class.investment-volume.single': 'Einzelinvestments',
  'my-objects.pipeline.assets-class.investment-volume.total': 'Präferiertes Investmentvolumen',
  'my-objects.pipeline.assets-class.investment-volume.from': 'Kaufpreis von',
  'my-objects.pipeline.assets-class.investment-volume.to': 'Kaufpreis bis',
  'my-objects.pipeline.assets-class.investment-volume.portfolio': 'Portfolio gesucht',
  'my-objects.pipeline.assets-class.investment-volume.portfolio.yes': 'Ja',
  'my-objects.pipeline.assets-class.investment-volume.portfolio.no': 'Nein',
  'my-objects.pipeline.assets-class.portfolio-investment-volume': 'Einzelinvestments',
  'my-objects.pipeline.assets-class.portfolio-investment-volume.single': 'Portfolioinvestments',
  'my-objects.pipeline.assets-class.portfolio-investment-volume.total': 'Präferiertes Investmentvolumen',
  'my-objects.pipeline.assets-class.portfolio-investment-volume.min-invest-single': 'Mindestinvestment pro Einzelobjekt',
  'my-objects.pipeline.assets-class.portfolio-investment-volume.from': 'Kaufpreis von',
  'my-objects.pipeline.assets-class.portfolio-investment-volume.to': 'Kaufpreis bis',
  'my-objects.pipeline.assets-class.total-investment': 'Gesamtinvestitionskosten',
  'my-objects.pipeline.assets-class.total-investment.from': 'von',
  'my-objects.pipeline.assets-class.total-investment.to': 'bis',
  'my-objects.pipeline.assets-class.plot-price': 'Grundstückspreis pro qm',
  'my-objects.pipeline.assets-class.plot-price.from': 'von',
  'my-objects.pipeline.assets-class.plot-price.to': 'bis',
  'my-objects.pipeline.assets-class.type-of-usage': 'Art der baulichen Nutzung',

  'person.title.options.dr': 'Dr.',
  'person.title.options.prof': 'Prof.',
  'person.title.options.prof-dr': 'Prof. Dr.',

  'person.salutation.options.man': 'Herr',
  'person.salutation.options.woman': 'Frau',
  'person.salutation.options.diverse': 'Divers',

  'person.responsibility.options.purchase': 'Einkauf',
  'person.responsibility.options.sales': 'Verkauf',

  'company.intention.options.investor': 'Investor',
  'company.intention.options.projektentwickler': 'Projektentwickler',
  'company.intention.options.bautraeger': 'Bauträger',
  'company.intention.options.asset-property-investment-manager': 'Asset - / Property - / Investment Manager',
  'company.intention.options.pensionskasse': 'Pensionskasse',
  'company.intention.options.bank': 'Bank',
  'company.intention.options.stiftung': 'Stiftung',
  'company.intention.options.family-office': 'Family Office',
  'company.intention.options.stadt-gemeinde': 'Stadt / Gemeinde',
  'company.intention.options.verkaufsberater': 'Verkaufsberater',
  'company.intention.options.hnwi': 'HNWI',
  'company.intention.options.privatperson': 'Privatperson',

  'company.corporate-form.options.einzelunternehmen': 'Einzelunternehmen',
  'company.corporate-form.options.gbr': 'GbR',
  'company.corporate-form.options.ohg': 'OHG',
  'company.corporate-form.options.gmbh': 'GmbH',
  'company.corporate-form.options.ug-haftungsbeschraenkt': 'UG (haftungsbeschränkt)',
  'company.corporate-form.options.gmbh-and-co-kg': 'GmbH & Co. KG',
  'company.corporate-form.options.kg': 'KG',
  'company.corporate-form.options.kg-aa': 'KG aA',
  'company.corporate-form.options.ag': 'AG',
  'company.corporate-form.options.partg': 'PartG',
  'company.corporate-form.options.partg-mbb': 'PartG mbB',
  'company.corporate-form.options.eg': 'eG',
  'company.corporate-form.options.ev': 'e.V.',
  'company.corporate-form.options.rechtsfaehige-stiftung': 'rechtsfähige Stiftung',
  'company.corporate-form.options.treuhaenderische-stiftung': 'treuhänderische Stiftung',
  'company.corporate-form.options.koerperschaft-d-oeffentl-rechts': 'Körperschaft d. Öffentl. Rechts',
  'company.corporate-form.options.anstalt-d-oeffentl-rechts': 'Anstalt d. Öffentl. Rechts',
  'company.corporate-form.options.se': 'SE',
  'company.corporate-form.options.ltd': 'Ltd.',
  'company.corporate-form.options.sarl': 'SARL',
  'company.corporate-form.options.sa': 'S.A.',
  'company.corporate-form.options.seca': 'SECA',
  'company.corporate-form.options.scs': 'SCS',
  'company.corporate-form.options.scsp': 'SCSp',
  'company.corporate-form.options.secs': 'SECS',
  'company.corporate-form.options.sarl-s': 'SARL-S',

  'object.asset-class.options.wohnen': 'Wohnen',
  'object.asset-class.options.buero': 'Büro',
  'object.asset-class.options.handel-retail': 'Handel / Retail',
  'object.asset-class.options.hotel': 'Hotel',
  'object.asset-class.options.logistik-industrie': 'Logistik / Industrie',
  'object.asset-class.options.healthcare-medical': 'Healthcare / Medical',
  'object.asset-class.options.micro-und-serviced-living': 'Micro- und Serviced Living',
  'object.asset-class.options.kapitalgeber': 'Kapitalgeber',
  'object.asset-class.options.freies-ankaufsprofil': 'Freies Ankaufsprofil',

  'object.project-type.options.stock': 'Bestand',
  'object.project-type.options.project-development': 'Projektentwicklung',
  'object.project-type.options.plot': 'Grundstück',

  'object.object-type.living.options.mehrfamilienhaus': 'Mehrfamilienhaus',
  'object.object-type.living.options.wohn-und-geschaeftshaus': 'Wohn- und Geschäftshaus',
  'object.object-type.living.options.wohnsiedlung': 'Wohnsiedlung',
  'object.object-type.living.options.geschosswohnungsbau': 'Geschosswohnungsbau',
  'object.object-type.living.options.quartiersentwicklungen': 'Quartiersentwicklungen',
  'object.object-type.commerce-and-retail.options.high-street-retail': 'High Street Retail',
  'object.object-type.commerce-and-retail.options.shoppingzentren': 'Shoppingzentren',
  'object.object-type.commerce-and-retail.options.fachmaerkte': 'Fachmärkte',
  'object.object-type.commerce-and-retail.options.fachmarktzentren': 'Fachmarktzentren',
  'object.object-type.commerce-and-retail.options.einkaufsparks': 'Einkaufsparks',
  'object.object-type.commerce-and-retail.options.lebensmittelmaerkte': 'Lebensmittelmärkte',
  'object.object-type.commerce-and-retail.options.baumaerkte': 'Baumärkte',
  'object.object-type.hotel.options.all-suite-hotel': 'All-Suite-Hotel',
  'object.object-type.hotel.options.apart-apartmenthotel': 'Apart- / Apartmenthotel',
  'object.object-type.hotel.options.boarding-apartmenthaus': 'Boarding- / Apartmenthaus',
  'object.object-type.hotel.options.gaestehaus': 'Gästehaus',
  'object.object-type.hotel.options.gasthof': 'Gasthof',
  'object.object-type.hotel.options.hotel': 'Hotel',
  'object.object-type.hotel.options.hotel-garni': 'Hotel garni',
  'object.object-type.hotel.options.jugendherberge': 'Jugendherberge',
  'object.object-type.hotel.options.motel': 'Motel',
  'object.object-type.hotel.options.pension': 'Pension',
  'object.object-type.logistic.options.distributionshalle': 'Distributionshalle',
  'object.object-type.logistic.options.lagerhalle': 'Lagerhalle',
  'object.object-type.logistic.options.umschlaghalle': 'Umschlaghalle',
  'object.object-type.logistic.options.hochregallager': 'Hochregallager',
  'object.object-type.logistic.options.speziallager': 'Speziallager',
  'object.object-type.healthcare.options.pflege': 'Pflege',
  'object.object-type.healthcare.options.betreutes-wohnen': 'Betreutes Wohnen',
  'object.object-type.healthcare.options.tagespflege': 'Tagespflege',
  'object.object-type.healthcare.options.ambulante-pflege': 'Ambulante Pflege',
  'object.object-type.healthcare.options.aerztehaus': 'Ärztehaus',
  'object.object-type.healthcare.options.behinderteneinrichtung': 'Behinderteneinrichtung',
  'object.object-type.micro-and-serviced-living.options.studenten-apartments': 'Studenten Apartments',
  'object.object-type.micro-and-serviced-living.options.business-apartments': 'Business Apartments',
  'object.object-type.micro-and-serviced-living.options.mikroapartments': 'Mikroapartments',
  'object.object-type.free-selection.options.parkhaeuser': 'Parkhäuser',
  'object.object-type.free-selection.options.casinos-spielhallen': 'Casinos / Spielhallen',
  'object.object-type.free-selection.options.rastplaetze': 'Rastplätze',
  'object.object-type.free-selection.options.mcdonalds': 'McDonalds',
  'object.object-type.free-selection.options.burger king': 'Burger King',
  'object.object-type.free-selection.options.staedtische-einrichtungen-beheorden': 'Städtische Einri.free-selectionchtungen (Behörden)',
  'object.object-type.free-selection.options.kindergaerten': 'Kindergärten',
  'object.object-type.free-selection.options.burgen-schloesser': 'Burgen / Schlösser',
  'object.object-type.free-selection.options.weingueter': 'Weingüter',
  'object.object-type.free-selection.options.labore': 'Labore',
  'object.object-type.free-selection.options.bahnhoefe': 'Bahnhöfe',
  'object.object-type.free-selection.options.raststaetten': 'Raststätten',
  'object.object-type.free-selection.options.freizeitparks': 'Freizeitparks',
  'object.object-type.free-selection.options.flughafengebaeude': 'Flughafengebäude',
  'object.object-type.free-selection.options.kraftwerke': 'Kraftwerke',
  'object.object-type.free-selection.options.sporteinrichtungen': 'Sporteinrichtungen',
  'object.object-type.free-selection.options.autowaschanlagen': 'Autowaschanlagen',
  'object.object-type.free-selection.options.strassen': 'Straßen',
  'object.object-type.free-selection.options.infrastruktur': 'Infrastruktur',
  'object.object-type.free-selection.options.ferienwohnungen-ferienanlagen': 'Ferienwohnungen (Ferienanlagen)',
  'object.object-type.free-selection.options.autorennstrecken': 'Autorennstrecken',

  'object.type-of-usage.options.kleinsiedlungsgebiet-ws': 'Kleinsiedlungsgebiet (WS)',
  'object.type-of-usage.options.reines-wohngebiet-wr': 'Reines Wohngebiet (WR)',
  'object.type-of-usage.options.allgemeines-wohngebiet-wa': 'Allgemeines Wohngebiet (WA)',
  'object.type-of-usage.options.besonderes-wohngebiet-wb': 'Besonderes Wohngebiet (WB)',
  'object.type-of-usage.options.dorfgebiet-md': 'Dorfgebiet (MD)',
  'object.type-of-usage.options.mischgebiet-mi': 'Mischgebiet (MI)',
  'object.type-of-usage.options.kerngebiet-mk': 'Kerngebiet (MK)',
  'object.type-of-usage.options.gewerbegebiet-ge': 'Gewerbegebiet (GE)',
  'object.type-of-usage.options.industriegebiet-gi': 'Industriegebiet (GI)',

  'object.type-of-usage.sub-options.wohnen': 'Wohnen',
  'object.type-of-usage.sub-options.buero-gewerbe': 'Büro / Gewerbe',
  'object.type-of-usage.sub-options.handel-retail': 'Handel / Retail',
  'object.type-of-usage.sub-options.hotel': 'Hotel',
  'object.type-of-usage.sub-options.logistik-industrie': 'Logistik / Industrie',
  'object.type-of-usage.sub-options.healthcare': 'Healthcare',
  'object.type-of-usage.sub-options.micro-und-serviced-living': 'Micro- und Serviced Living',

  'dropdown.empty-selection': '-',

  'currency.symbol': ' €',
  'currency.string': ' EUR',

  'object-detail.tabs.object-profile': 'Objektprofil',
  'object-detail.tabs.storage': 'Datenraum',
  'object-detail.tabs.q-and-a': 'Q & A',
  'object-detail.tabs.chat': 'Nachrichten',

  'chat.MessagesTab.conversation.messagePlaceholder': 'Schreiben Sie eine Nachricht...',
  'chat.MessagesTab.conversation.attachments': 'Anhänge:',
  'chat.MessagesTab.conversation.attachments.upload-label': 'Dateien werden hochgeladen... ',
  'chat.MessagesTab.room-list.search': 'Suchen',
  'chat.headline': 'Nachrichten',

  'storage.breakcrumbs.home': 'Home',

  'storage.folder.options.building-regulations': 'Bauordnungsrecht',
  'storage.folder.options.building-planning-law': 'Bauplanungsrecht',
  'storage.folder.options.management': 'Bewirtschaftung',
  'storage.folder.options.accounting': 'Buchhaltung',
  'storage.folder.options.property': 'Eigentum',
  'storage.folder.options.introduction': 'Einleitung',
  'storage.folder.options.financing': 'Finanzierung',
  'storage.folder.options.building': 'Gebäude',
  'storage.folder.options.plot': 'Grundstück',
  'storage.folder.options.commercial-documents': 'Kaufmännische Unterlagen',
  'storage.folder.options.renter': 'Mieter',
  'storage.folder.options.project-cost-estimate': 'Projektkostenschätzung',
  'storage.folder.options.litigation': 'Rechtstreitigkeiten',

  'storage.document-type.options.building-regulations.other-permits': 'Andere Genehmigungen',
  'storage.document-type.options.building-regulations.building-permit': 'Baugenehmigungen',
  'storage.document-type.options.building-regulations.statics': 'Statik',

  'storage.document-type.options.building-planning-law.development-plan': 'Bebauungsplan',
  'storage.document-type.options.building-planning-law.official-certificates': 'Behördliche Bescheinigungen',
  'storage.document-type.options.building-planning-law.monument-protection': 'Denkmalschutz',
  'storage.document-type.options.building-planning-law.development-area': 'Entwicklungsgebiet/Erhaltungssatzung',
  'storage.document-type.options.building-planning-law.development': 'Erschließung',
  'storage.document-type.options.building-planning-law.zoning-plan': 'Flächennutzungsplan',
  'storage.document-type.options.building-planning-law.urban-development-contracts': 'Städtebauliche Verträge',

  'storage.document-type.options.management.statement-of-operating-costs': 'Betriebskostenaufstellung',
  'storage.document-type.options.management.management-contracts': 'Bewirtschaftungsverträge',
  'storage.document-type.options.management.proof-of-insurance': 'Versicherungsnachweise',
  'storage.document-type.options.management.insurance-damage': 'Versicherungsschaden',

  'storage.document-type.options.accounting.outgoing-invoice': 'Ausgangsrechnung',
  'storage.document-type.options.accounting.incoming-invoice': 'Eingangsrechnung',
  'storage.document-type.options.accounting.payment-order': 'Mahnbescheid',
  'storage.document-type.options.accounting.warning': 'Mahnung',

  'storage.document-type.options.property.leasehold-contracts': 'Erbbaurechtsverträge',
  'storage.document-type.options.property.land-register-extract': 'Grundbuchauszug',
  'storage.document-type.options.property.land-purchase-agreement': 'Grundstückskaufvertrag',
  'storage.document-type.options.property.commercial-register-extract': 'Handelsregisterauszug',
  'storage.document-type.options.property.neighborhood-contracts': 'Nachbarschaftsverträge',
  'storage.document-type.options.property.part-ownership': 'Teileigentum',

  'storage.document-type.options.introduction.photo': 'Foto',
  'storage.document-type.options.introduction.object-description': 'Objektbeschreibung',

  'storage.document-type.options.financing.general-correspondence': 'Allgemeine Korrespondenz',
  'storage.document-type.options.financing.loan-agreement': 'Kreditvertrag',
  'storage.document-type.options.financing.balance-confirmation': 'Saldenbestätigung',

  'storage.document-type.options.building.building-description': 'Baubeschreibung',
  'storage.document-type.options.building.warranty-mirror': 'Gewährleistungsspiegel',
  'storage.document-type.options.building.trade': 'Gewerk',
  'storage.document-type.options.building.sustainability': 'Nachhaltigkeit (inkl. Energieausweis)',
  'storage.document-type.options.building.maintenance-protocols-revision-documents-operating-instructions': 'Wartungsprotokolle / Revisionsunterlagen / Bedienungsanleitungen',
  'storage.document-type.options.building.views': 'Ansichten',
  'storage.document-type.options.building.electric': 'Elektro',
  'storage.document-type.options.building.layout': 'Grundriss',
  'storage.document-type.options.building.heater': 'Heizung',
  'storage.document-type.options.building.climate': 'Klima',
  'storage.document-type.options.building.ventilation': 'Lüftung',
  'storage.document-type.options.building.sanitary': 'Sanitär',
  'storage.document-type.options.building.cuts': 'Schnitte',
  'storage.document-type.options.building.fire-protection': 'Brandschutz',
  'storage.document-type.options.building.area-measurement': 'Flächenaufmaß',
  'storage.document-type.options.building.building-construction-status-analysis': 'Gebäude / Bauzustandsanalyse',

  'storage.document-type.options.plot.ground-value-card': 'Bodenrichtwertkarte',
  'storage.document-type.options.plot.land-map-cadastre-official-site-plan': 'Flurkarte / Kataster / amtlicher Lageplan',
  'storage.document-type.options.plot.foundation-building-site-appraisal': 'Gründungs- / Baugrundgutachten',
  'storage.document-type.options.plot.superstructures': 'Überbauungen',
  'storage.document-type.options.plot.environment': 'Umwelt',

  'storage.document-type.options.commercial-documents.offer': 'Angebot',
  'storage.document-type.options.commercial-documents.order-confirmation': 'Auftragsbestätigung',
  'storage.document-type.options.commercial-documents.announcement': 'Ausschreibung',
  'storage.document-type.options.commercial-documents.payment-order': 'Mahnbescheid',
  'storage.document-type.options.commercial-documents.warning': 'Mahnung',
  'storage.document-type.options.commercial-documents.invoice': 'Rechnung',

  'storage.document-type.options.renter.house-rule': 'Hausordnung',
  'storage.document-type.options.renter.terminations': 'Kündigungen',
  'storage.document-type.options.renter.rent-adjustments-long-term-rent-invoice': 'Mietanpassungen / Dauermietrechnung',
  'storage.document-type.options.renter.tenant-security': 'Mietersicherheiten',
  'storage.document-type.options.renter.rent-arrears': 'Mietrückstände',
  'storage.document-type.options.renter.rental-agreements-including-supplements': 'Mietverträge (inkl. Nachträge)',
  'storage.document-type.options.renter.invoice-for-additional-costs': 'Nebenkostenabrechnung',
  'storage.document-type.options.renter.handover-protocols': 'Übergabeprotokolle',
  'storage.document-type.options.renter.overview-of-tenancies-usage-relationships': 'Übersicht Mietverhältnisse / Nutzungsverhältnisse',
  'storage.document-type.options.renter.important-correspondence': 'Wichtiger Schriftverkehr',

  'storage.document-type.options.litigation.guarantees': 'Bürgschaften',
  'storage.document-type.options.litigation.court-costs': 'Gerichtskosten',
  'storage.document-type.options.litigation.assessment': 'Gutachten',
  'storage.document-type.options.litigation.legal-action': 'Klage',
  'storage.document-type.options.litigation.statement-of-defense': 'Klageerwiderung',
  'storage.document-type.options.litigation.cost-fixing-decision': 'Kostenfestsetzungsbeschluss',
  'storage.document-type.options.litigation.events': 'Termine',
  'storage.document-type.options.litigation.verdict': 'Urteil',
  'storage.document-type.options.litigation.enforcement-order': 'Vollstreckungsbescheid',
  'storage.document-type.options.litigation.general-correspondence': 'Allgemeine Korrespondenz',
  'storage.document-type.options.litigation.notification-of-defects': 'Mängelrüge',
  'storage.document-type.options.litigation.other-documents-contracts-agreements': 'Sonstige Dokumente / Verträge / Vereinbarungen',

  'storage.specification.options.building-permit.cancellation': 'Abbruch',
  'storage.specification.options.building-permit.deviation': 'Abweichung',
  'storage.specification.options.building-permit.application': 'Antrag',
  'storage.specification.options.building-permit.start-of-building': 'Baubeginn',
  'storage.specification.options.building-permit.permit': 'Genehmigung',
  'storage.specification.options.building-permit.preliminary-inquiry': 'Voranfrage',
  'storage.specification.options.building-permit.preliminary-decision': 'Vorbescheid',
  'storage.specification.options.building-permit.building-structure-inspection': 'Bausubstanzbesichtigung',
  'storage.specification.options.building-permit.change-of-use': 'Nutzungsänderung',
  'storage.specification.options.building-permit.statics': 'Statik',

  'storage.specification.options.official-certificates.contaminated-sites': 'Altlasten',
  'storage.specification.options.official-certificates.resident-certificate': 'Anliegerbescheinigung',
  'storage.specification.options.official-certificates.construction-load': 'Baulast',
  'storage.specification.options.official-certificates.geothermal-probe': 'Erdwärmesonde',
  'storage.specification.options.official-certificates.completion-notice': 'Fertigstellungsbescheid',
  'storage.specification.options.official-certificates.rainwater': 'Niederschlagswasser',
  'storage.specification.options.official-certificates.use-permit': 'Nutzungsgenehmigung',
  'storage.specification.options.official-certificates.shell-acceptance': 'Rohbauabnahme',
  'storage.specification.options.official-certificates.others': 'Sonstige',
  'storage.specification.options.official-certificates.division-permit': 'Teilungsgenehmigung',
  'storage.specification.options.official-certificates.heat-pump': 'Wärmepumpe',
  'storage.specification.options.official-certificates.grant-notification': 'Zuwendungsbescheid',

  'storage.specification.options.management-contracts.elevator-maintenance': 'Aufzugswartung',
  'storage.specification.options.management-contracts.garden-maintenance': 'Gartenpflege',
  'storage.specification.options.management-contracts.caretaker-service': 'Hausmeisterservice',
  'storage.specification.options.management-contracts.maintenance': 'Wartung',
  'storage.specification.options.management-contracts.winter-service': 'Winterdienst',
  'storage.specification.options.management-contracts.meter-reading': 'Zählerablesung',

  'storage.specification.options.outgoing-invoice.rental-period-invoice': 'Mietdauerrechnung',
  'storage.specification.options.outgoing-invoice.meter-load-to-tenants': 'Meterbelastung an Mieter',
  'storage.specification.options.outgoing-invoice.others': 'Sonstige',

  'storage.specification.options.incoming-invoice.invest': 'Invest',
  'storage.specification.options.incoming-invoice.rent': 'Miete',
  'storage.specification.options.incoming-invoice.repair': 'Reparatur',
  'storage.specification.options.incoming-invoice.others': 'Sonstige',
  'storage.specification.options.incoming-invoice.insurance': 'Versicherung',
  'storage.specification.options.incoming-invoice.maintenance': 'Wartung',

  'storage.specification.options.fire-protection.perpetration': 'Begehung',
  'storage.specification.options.fire-protection.valuation': 'Bewertung',
  'storage.specification.options.fire-protection.concept': 'Konzept',
  'storage.specification.options.fire-protection.exams': 'Prüfungen',
  'storage.specification.options.fire-protection.instruction': 'Unterweisung',
  'storage.specification.options.fire-protection.key-fire-department': 'Schlüssel Feuerwehr',

  'storage.specification.options.building-construction-status-analysis.site-manager-report': 'Bauleiterbericht',
  'storage.specification.options.building-construction-status-analysis.construction-diary': 'Bautagebuch',
  'storage.specification.options.building-construction-status-analysis.disturbance-notice': 'Behinderungsanzeige',
  'storage.specification.options.building-construction-status-analysis.appraisal': 'Wertgutachten',

  'storage.specification.options.foundation-building-site-appraisal.soil-survey-of-contaminated-sites': 'Bodengutachten Altlasten',
  'storage.specification.options.foundation-building-site-appraisal.geological-survey-of-the-soil': 'Bodengutachten geologisch',
  'storage.specification.options.foundation-building-site-appraisal.ordnance': 'Kampfmittel',

  'storage.specification.options.environment.contamination': 'Kontaminierung',
  'storage.specification.options.environment.plants': 'Pflanzen',
  'storage.specification.options.environment.animals': 'Tiere',
}

export const langKey = 'DE'

export default translations
