import { useEffect, useState } from 'react'
import './ImageWrapper.scss'
import { getUUID } from '../../utility'
import UploadIcon from '@mui/icons-material/Upload'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Storage } from 'aws-amplify'
import path from 'path'

const ImageWrapper = ({ imageKey, fallbackImage, round, uploadedCallback }) => {
  const uuid = getUUID()
  const [processing, setProcessing] = useState(false)
  const [image, setImage] = useState(false)

  useEffect(() => {
    const resolveImage = async () => {
      if (imageKey) {
        const image = await Storage.get(imageKey)
        setImage(image)
      }
    }
    if (imageKey) {
      resolveImage()
    } else {
      setImage(fallbackImage)
    }
  }, [fallbackImage, imageKey])

  const uploadImage = async (files) => {
    if (files.length === 1) {
      const file = files[0]
      setProcessing(true)
      const name = `${getUUID()}${path.extname(file.name)}`
      const { key } = await Storage.put(name, file, { ACL: 'public-read' })
      if (key) {
        const image = await Storage.get(key)
        if (image) {
          setImage(image)
          uploadedCallback(key)
        }
      }
      setProcessing(false)
    }
  }

  const uploadHandler =
    typeof uploadedCallback === 'function' ? (
      <div className="upload-handler">
        <input type="file" id={uuid} className="upload-input" accept="image/*" onChange={(e) => uploadImage(e.target.files)} />
        {!processing && (
          <label htmlFor={uuid} className="upload-input-label">
            <UploadIcon />
          </label>
        )}
        {processing && (
          <div className="processing">
            <AutorenewIcon />
          </div>
        )}
      </div>
    ) : (
      ''
    )

  if (round) {
    return (
      <div className="universal-image-wrapper-round" style={{ backgroundImage: `url(${image})` }}>
        {uploadHandler}
      </div>
    )
  }
  return (
    <div className="universal-image-wrapper">
      <img src={image} alt={image} />
      {uploadHandler}
    </div>
  )
}

export default ImageWrapper
