import { Switch, Route, Redirect } from 'react-router-dom'
import Employees from './components/Dashboard/Employees/Employees'
import Holiday from './components/Dashboard/Holiday/Holiday'
import Profile from './components/Dashboard/Profile/Profile'
import NotFound from './components/NotFound/NotFound'

function DashboardRouting({ match }) {
  return (
    <Switch>
      {/* EMPLOYEE */}
      <Route exact={true} path={`${match.path}/employees`} component={Employees} />
      {/* HOLIDAY */}
      <Route exact={true} path={`${match.path}/holiday`} component={Holiday} />
      {/* PROFILE */}
      <Route exact={true} path={`${match.path}`} component={Profile} />
      <Route exact={true} path={`${match.path}/employees/:id`} component={Profile} />
      <Route component={NotFound} />
      <Route>
        <Redirect to={'/login'} />
      </Route>
    </Switch>
  )
}

export default DashboardRouting
