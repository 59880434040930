import Layout from '../Layout'
import './Dashboard.scss'
import logo from '../../../assets/image/logo.png'
import { projectName } from '../../../utility'
import { useDispatch, useSelector } from 'react-redux'
import Link from '../../UI/Link/Link'
import { setUser } from '../../../action/user'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'

const Dashboard = (props) => {
  const translation = useSelector((state) => state.translation)
  const user = useSelector((state) => state.user)
  const { overwriteNavigationComponent } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const logout = async () => {
    await Auth.signOut()
    dispatch(setUser(null))
    history.push('/')
  }

  return (
    <Layout authRequired={true} header={false} fullsize={true}>
      <div id="dashboard-layout">
        <div id="sidebar">
          <div className="logo">
            <img src={logo} alt={projectName} />
          </div>
          {!overwriteNavigationComponent && (
            <div className="navigation-wrapper">
              {user && user.admin === true && (
                <div className="navigation">
                  <div className="title">{translation['layout-dashboard.navigation.admin.title']}</div>
                  <div className="item">
                    <Link href="/admin/users" text={translation['layout-dashboard.navigation.admin.settings']} />
                  </div>
                </div>
              )}
              <div className="navigation">
                <div className="title">{translation['layout-dashboard.navigation.general.title']}</div>
                <div className="item">
                  <Link href="/dashboard" text={translation['layout-dashboard.navigation.profile']} />
                </div>
                <div className="item">
                  <Link href="/dashboard/holiday" text={translation['layout-dashboard.navigation.vacation']} />
                </div>
                <div className="item">
                  <Link href="/dashboard/employees" text={translation['layout-dashboard.navigation.employees']} />
                </div>
              </div>
              <div className="logout-wrapper">
                <div className="logout" onClick={logout}>
                  {translation['layout-dashboard.logout']}
                </div>
              </div>
            </div>
          )}
          {overwriteNavigationComponent && overwriteNavigationComponent}
        </div>
        <div id="dashboard-content-wrapper" className={`${props.card ? 'card' : ''}`}>
          <div id="dashboard-content">{props.children}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard
