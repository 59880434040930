import { API } from 'aws-amplify'
import DashboardLayout from '../../Layout/Dashboard/Dashboard'
import Input from '../../UI/Input/Input'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import './Holiday.scss'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import Button from '../../UI/Button/Button'
import Request from './Request/Request'

const Holiday = () => {
  const user = useSelector((state) => state.user)
  const translation = useSelector((state) => state.translation)
  const [start, setStart] = useState(new Date().toISOString())
  const [end, setEnd] = useState(new Date().toISOString())
  const [requiredDays, setRequiredDays] = useState('')
  const [message, setMessage] = useState(null)
  const [requests, setRequests] = useState(null)

  useEffect(() => {
    const pullRequests = async () => {
      const { status, list } = await API.get('intranet', '/holiday/request/list')
      if (status && list) {
        console.log(list)
        setRequests(list)
      }
    }
    if (user && user.admin === true) {
      pullRequests()
    }
  }, [user])

  const submit = async () => {
    const { status } = await API.post('intranet', '/holiday/request', { body: { start, end, requiredDays, userId: user.id, firstname: user.firstname, lastname: user.lastname } })
    if (status) {
      setMessage(translation['dashboard.holiday.request.send.success'])
    } else {
      setMessage(translation['dashboard.holiday.request.send.failure'])
    }
  }

  const isAdmin = () => user && user.admin === true

  return (
    <DashboardLayout card={true}>
      <div id="holiday">
        <CardHeader headline={translation['dashboard.holiday.headline']} />
        <CardContent>
          <Input className="input" type="date" value={start} onChange={setStart} placeholder={translation['dashboard.holiday.start']} />
          <Input className="input" type="date" value={end} onChange={setEnd} placeholder={translation['dashboard.holiday.end']} />
          <Input className="input" type="number" value={requiredDays} onChange={setRequiredDays} placeholder={translation['dashboard.holiday.days']} />
          {message && <div className="message">{message}</div>}
          <Button fullWidth={false} onClick={submit} text={translation['dashboard.holiday.submit']} />
          {isAdmin() && requests && (
            <div className="requests">
              {requests.map((r) => (
                <Request data={r} />
              ))}
            </div>
          )}
        </CardContent>
      </div>
    </DashboardLayout>
  )
}

export default Holiday
