import './PasswordLost.scss'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Layout from '../../Layout/Layout'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Link from '../../UI/Link/Link'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

const Login = () => {
  const [mail, setMail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const translation = useSelector((state) => state.translation)
  const [message, setMessage] = useState(null)
  const [codeSent, setCodeSent] = useState(false)
  const history = useHistory()

  const submit = async (e) => {
    e.preventDefault()
    if (mail.length > 0) {
      try {
        await Auth.forgotPassword(mail)
        setMessage(translation['password-reset.mail-sent'])
        setCodeSent(true)
      } catch (error) {
        setMessage(translation['password-lost.unknown-error'])
      }
    } else {
      setMessage(translation['password-lost.missing-input'])
    }
  }

  const reset = async (e) => {
    e.preventDefault()
    if (mail.length > 0) {
      try {
        await Auth.forgotPasswordSubmit(mail, code, password)
        history.push('/login')
      } catch (error) {
        setMessage(translation['password-lost.unknown-error'])
      }
    } else {
      setMessage(translation['password-lost.missing-input'])
    }
  }

  if (!codeSent)
    return (
      <Layout>
        <div id="password-lost">
          <div className="form-wrapper">
            <div className="headline">{translation['password-lost.headline']}</div>
            <form onSubmit={submit}>
              <div className="element-wrapper">
                <Input type="email" placeholder={translation['password-lost.mail']} value={mail} onChange={setMail} />
              </div>
              {message ? <div className="message">{message}</div> : ''}
              {!message ? <div className="message">{translation['password-lost.explain']}</div> : ''}
              <div className="element-wrapper">
                <Button text={translation['password-lost.button']} type={'submit'} />
              </div>
              <div className="switches">
                <div className="switch">
                  <Link href="/login" text={translation['password-lost.login']} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  return (
    <Layout>
      <div id="password-reset">
        <div className="form-wrapper">
          <div className="headline">{translation['password-reset.headline']}</div>
          <form onSubmit={reset}>
            <div className="element-wrapper">
              <Input placeholder={translation['password-reset.code']} value={code} onChange={setCode} />
            </div>
            <div className="element-wrapper">
              <Input type="password" placeholder={translation['password-reset.password']} value={password} onChange={setPassword} />
            </div>
            {message ? <div className="message">{message}</div> : ''}
            {!message ? <div className="message">{translation['password-reset.explain']}</div> : ''}
            <div className="element-wrapper">
              <Button text={translation['password-reset.button']} type={'submit'} />
            </div>
            <div className="switches">
              <div className="switch">
                <Link href="/login" text={translation['password-reset.login']} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Login
