import { useEffect, useState } from 'react'
import { setUser } from '../../../action/user'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'

const Secure = (props) => {
  const authRequired = props.authRequired || false
  const dispatch = useDispatch()
  const history = useHistory()
  const [isEvaluated, setIsEvaluated] = useState(false)

  useEffect(() => {
    const evaluate = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user && user.username) {
          const { status, profile } = await API.get('intranet', `/employee/get/${user.username}`)
          if (status && profile) {
            dispatch(setUser(profile))
            setIsEvaluated(true)
          } else {
            dispatch(setUser(null))
            history.push('/login')
          }
        }
      } catch (err) {}
    }
    if (authRequired) {
      evaluate()
    } else {
      setIsEvaluated(true)
    }
  }, [isEvaluated, authRequired, dispatch, history])

  return isEvaluated ? <>{props.children}</> : <></>
}

export default Secure
