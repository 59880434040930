import Container from '@mui/material/Container'
import './Footer.scss'
import logo from '../../../assets/image/logo.png'
import { projectName, companyName } from '../../../utility'

const Footer = () => {
  return (
    <div id="footer">
      <Container className="container">
        <div className="logo">
          <img src={logo} alt={projectName} />
        </div>
        <div className="copyright">
          <div>Copyright &copy; {new Date().getFullYear()}</div>
          <div>{companyName}</div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
