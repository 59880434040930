import { Switch, Route } from 'react-router-dom'
import Users from './components/Admin/Users/Users'

function AdminRouting({ match }) {
  return (
    <Switch>
      {/* USERS */}
      <Route exact={true} path={`${match.path}/users`} component={Users} />
    </Switch>
  )
}

export default AdminRouting
