import { TextField, InputAdornment } from '@mui/material'
import { addMissingZero } from '../../../utility'
import './Input.scss'
import NumberFormat from 'react-number-format'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'

const CurrencyFormatter = forwardRef((props, ref) => {
  const { onChange, ...other } = props
  const translation = useSelector((state) => state.translation)

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => onChange({ target: { value: values.value } })}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
      suffix={translation['currency.string']}
    />
  )
})

const KilometerFormatter = forwardRef((props, ref) => {
  const { onChange, ...other } = props
  const translation = useSelector((state) => state.translation)

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => onChange({ target: { value: values.value } })}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
      suffix={translation['kilometer']}
    />
  )
})

const Input = (props) => {
  const { type, value, onChange, placeholder, variant, suffixSymbol, disabled } = props

  const propIsMissing = () => {
    const requiredProps = ['value', 'placeholder']
    return requiredProps.reduce((acc, prop) => (acc === true ? true : props[prop] === undefined), false)
  }

  const getValue = () => {
    // this means the date is provided by the database and has to be transformed into yyyy-MM-dd
    if (type === 'date' && value && value.endsWith('Z')) {
      const date = new Date(value)
      return `${date.getFullYear()}-${addMissingZero(date.getMonth() + 1)}-${addMissingZero(date.getDate())}`
    }
    return value || ''
  }
  if (propIsMissing()) return <></>
  let additionalProps = {}
  if (type === 'currency') {
    additionalProps.inputComponent = CurrencyFormatter
  } else if (type === 'kilometer') {
    additionalProps.inputComponent = KilometerFormatter
  }
  if (suffixSymbol) {
    additionalProps = {
      endAdornment: <InputAdornment position="end">{suffixSymbol}</InputAdornment>,
    }
  }
  return (
    <TextField
      className={`custom-MUI-input ${props.className}`}
      type={type || 'text'}
      value={getValue()}
      onChange={(e) => onChange(e.target.value)}
      label={placeholder}
      InputProps={additionalProps}
      variant={variant ? variant : 'filled'}
      fullWidth={true}
      disabled={disabled || false}
    />
  )
}

export default Input
